(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/component-helper/assets/javascripts/component-helper.js') >= 0) return;  svs.modules.push('/components/utils/component-helper/assets/javascripts/component-helper.js');

'use strict';

var isServerSide = typeof exports === 'object';
var config; var hbs;

if (isServerSide) {
  hbs = require('hbs');
  config = require('trinidad-core').core.config;
} else {
  hbs = Handlebars;
  config = { options: svs.core.config.data.svsconfig };
}


svs.utils = svs.utils || {};
svs.utils.component_helper = svs.utils.component_helper || {};
svs.utils.component_helper.helper = function(args, ns) {
  var component = String(args[0]);
  var context = {};
  var options = {};
  var parts;
  var template;

  if (Object.prototype.hasOwnProperty.call(args[args.length - 1], 'hash')) {
    context = args[args.length - 1];
  }

  if (args[1] && !Object.prototype.hasOwnProperty.call(args[1], 'hash')) {
    options = args[1] === Object(args[1]) ? args[1] : JSON.parse(args[1]);
  }

  for (var hashKey in context.hash) {
    if (Object.prototype.hasOwnProperty.call(context.hash, hashKey)) {
      if (hashKey.indexOf('data-') > -1) {
        if (!options.data) {
          options.data = {};
        }
        options.data[hashKey.replace('data-', '')] = context.hash[hashKey];
      } else {
        options[hashKey] = context.hash[hashKey];
      }
    }
  }

  if (isServerSide) {
    for (var key in context.data.root) {
      if (Object.prototype.hasOwnProperty.call(context.data.root, key)) {
        if (!Object.prototype.hasOwnProperty.call(options, key) &&
          key !== 'title' &&
          key !== 'data' &&
          key !== 'content'
        ) {
          options[key] = context.data.root[key];
        }
      }
    }
  }

  parts = component.replace(/-/, '_').split('/');

  if (isServerSide) {
    template = hbs.cache['components-' + ns + '-' + parts[0] + '-' + (parts.length > 1 ? parts[1] : parts[0]) + '_partial'];
  } else {
    template = ns[parts[0]].partials[parts.length > 1 ? parts[1] : parts[0]];
  }

  if (template) {
    if (Object.prototype.hasOwnProperty.call(context, 'fn')) {
      if (String(context.fn(this)).trim() !== '') {
        options.content = new hbs.SafeString(context.fn(this));
      }
    }
    options.__uniqueId = Math.round(Math.random() * 100000 + 1000);
    options.__cdn = config.options.cdn;

    return new hbs.SafeString(template(options));
  }
};

if (isServerSide) {
  module.exports = svs.utils.component_helper.helper;
}


 })(window);